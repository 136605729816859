import { computed, type ComputedRef } from 'vue';
import { useAdditionalProductStore } from '@/stores/additional-product';
import { additionalProductApi, type CtxProduct } from '@containex/portal-backend-api-client';
import { useMarketQuery } from '@/composables/market';
import { useCartQuery } from '@/composables/cart';
import { QuantityUnit } from '@containex/portal-backend-dto';
import { httpClient } from '@/common/api/http-client';

export interface AdditionalProductQuery {
    additionalProducts: ComputedRef<CtxProduct[]>;
    addableAdditionalProducts: ComputedRef<AddableAdditionalProduct[]>;
}

interface AddableAdditionalProduct {
    product: CtxProduct;
    quantityUnit: QuantityUnit;
    price: number;
}

export interface AdditionalProductAction {
    fetchAdditionalProductsIfMissing(): Promise<void>;
}

export function useAdditionalProductQuery(): AdditionalProductQuery {
    const additionalProductStore = useAdditionalProductStore();

    return {
        // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
        additionalProducts: computed(() => additionalProductStore.additionalProducts as CtxProduct[]),
        addableAdditionalProducts: computed(() => {
            return additionalProductStore.additionalProducts.flatMap((product) => {
                const variant = product.variants.find((item) => item.original_price != null) ?? product.variants[0];
                const quantityUnit = variant?.prices[0]?.quantity_unit;

                if (variant?.original_price == null || quantityUnit == null) {
                    return [];
                }

                return {
                    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
                    product: product as CtxProduct,
                    quantityUnit,
                    price: variant.original_price,
                };
            });
        }),
    };
}

export function useAdditionalProductAction(): AdditionalProductAction {
    const additionalProductStore = useAdditionalProductStore();
    const { currentSalesChannelId } = useMarketQuery();
    const { cart, currentRegionId } = useCartQuery();

    return {
        async fetchAdditionalProductsIfMissing(): Promise<void> {
            const { data } = await additionalProductApi.fetchAdditionalProducts(httpClient, {
                cartId: cart.value?.id,
                regionId: currentRegionId.value,
                salesChannelId: currentSalesChannelId.value,
            });

            // Product is a very complex type and is thus not depicted via the api on purpose
            // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
            additionalProductStore.additionalProducts = data.products as unknown[] as CtxProduct[];
        },
    };
}
