import { defineStore } from 'pinia';
import { type CtxProduct } from '@containex/portal-backend-api-client';

interface AdditionalProductState {
    additionalProducts: CtxProduct[];
}

export const useAdditionalProductStore = defineStore('additional-product', {
    state: (): AdditionalProductState => ({
        additionalProducts: [],
    }),
});
