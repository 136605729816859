import { type AllowedComponentProps, type App, type Component, createApp, h, type VNode, type VNodeProps } from 'vue';

type ComponentType<C> = C extends new (...args: unknown[]) => unknown ? InstanceType<C> : never;

type ComponentProps<C extends Component> = C extends new (...args: unknown[]) => unknown
    ? Omit<InstanceType<C>['$props'], keyof VNodeProps | keyof AllowedComponentProps>
    : never;

interface ReturnType {
    instance: VNode;

    destroy(): void;
}

export function createComponent<T extends Component>(
    app: App,
    component: ComponentType<T>,
    el: HTMLElement,
    props: ComponentProps<T>
): ReturnType {
    let expose: VNode | undefined;

    const childApp = createApp({
        render() {
            expose = h(component, props);

            return expose;
        },
    });

    // eslint-disable-next-line no-underscore-dangle
    Object.assign(childApp._context, app._context);

    childApp.mount(el);

    return {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        instance: expose!,
        destroy() {
            childApp.unmount();
        },
    };
}
