<script setup lang="ts">
    import { useI18n } from 'vue-i18n';
    import Button from 'primevue/button';
    import Card from 'primevue/card';
    import type { CtxProduct } from '@containex/portal-backend-api-client';
    import ProductImage from '@/components/ProductImage.vue';
    import { computed } from 'vue';
    import { usePriceFormatter } from '@/composables/formatPrice';
    import { QuantityUnit } from '@containex/portal-backend-dto';

    const props = defineProps<{
        product: CtxProduct;
        quantityUnit: QuantityUnit;
        price: number;
    }>();

    const emits = defineEmits<{
        'add-to-cart': [];
    }>();

    const { t } = useI18n();
    const { formatPrice } = usePriceFormatter();

    const priceDisplay = computed(() => priceDisplayFor(props.price, props.quantityUnit));

    function priceDisplayFor(price: number, unit: QuantityUnit): string {
        const priceString = formatPrice(price);

        switch (unit) {
            case QuantityUnit.DAYS:
                return t('PRODUCT.PRICE_DAILY', { price: priceString });
            case QuantityUnit.QUANTITY:
                return priceString;
        }
    }
</script>

<template>
    <Card>
        <template #content>
            <div class="content">
                <ProductImage
                    :url="product.thumbnail ?? undefined"
                    :alt="product.title ?? ''"
                    class="product-image"
                ></ProductImage>
                <div class="info text-base-semibold-line-height-auto">
                    {{ product.title }}<br />
                    {{ priceDisplay }}
                </div>
                <Button icon="pi pi-cart-plus" severity="primary" @click="emits('add-to-cart')" />
            </div>
        </template>
    </Card>
</template>

<style scoped lang="scss">
    @use 'src/styling/main';

    .content {
        display: flex;
        gap: 1ch;
        align-items: center;
    }

    .product-image {
        width: 50px;
    }

    .info {
        flex: 1;
    }
</style>
